<template>
    <AppFormCadastroSesmt
        :service="service"
        :form="form"
        backPath="/questionarios-sesmt"
        @onBeforeSave="onBeforeSave"
        @onAfterSave="onAfterSave"
        @onLoadDataEdit="onLoadDataEdit"
        @onValidate="onValidate"
        :botaoVoltar="false"
    >
        <template #content>
            <TabView :activeIndex="0">
                <TabPanel header="Geral">
                    <Geral
                        :form="form"
                        :clientes="clientes"
                        v-model:clientesSelecionados="clientesSelecionados"
                        :clientesSelecionados="clientesSelecionados"
                        @onLoadCustomers="loadCustomers"
                        :submitted="submitted"
                    />
                </TabPanel>
                <TabPanel header="Questionário">
                    <Questionario
                        v-model:perguntas-form="perguntasForm"
                        :perguntasForm="perguntasForm"
                        :perguntas="perguntas"
                        :submitted="submitted"
                    />
                </TabPanel>
            </TabView>
        </template>
    </AppFormCadastroSesmt>
</template>

<script>
import SesmtService, { SesmtQuestionarioCopiarService } from '../../services/SesmtService';
import EnumTipoPergunta from '../../enums/EnumTipoPergunta';
import FormularioFormatado from '@/pages/questionarios-sesmt/formularioFormatado';
import FormularioEdicaoFormatado from '@/pages/questionarios-sesmt/formularioEdicaoFormatado';
import Questionario from '@/pages/questionarios-sesmt/AbasCadastro/Questionario.vue';
import Geral from '@/pages/questionarios-sesmt/AbasCadastro/Geral.vue';
import { clone } from 'ramda';
const MODO = { ADICIONAR: 'ADICIONAR', EDITAR: 'EDITAR', COPIAR: 'COPIAR' };

export default {
    components: { Questionario, Geral },
    data() {
        return {
            form: {
                ativo: true,
                anexoObrigatorio: false,
                inserirAnexo: false,
                todosClientesMarcados: false
            },
            submitted: false,
            clientes: [],
            clientesSelecionados: [],
            perguntas: [],
            perguntasForm: [{ perguntaId: null }],
            tiposPergunta: EnumTipoPergunta,
            tipos: [
                {
                    value: 'IN',
                    label: 'Inicial'
                },
                {
                    value: 'AM',
                    label: 'Ambiente'
                }
                // Removido temporariamente pois ainda não foi desenvolvida essa parte
                // {
                //     value: 'IF',
                //     label: 'Identificação e caracterização de perigo/fator de risco'
                // }
            ],
            perguntasFormClone: [],
            perguntasRemovidasIds: [],
            modoInsercao: MODO.ADICIONAR
        };
    },
    created() {
        const isModoCopiar = this.$route.path?.includes?.('copiar');
        this.modoInsercao = isModoCopiar ? MODO.COPIAR : MODO.ADICIONAR;

        if (this.modoInsercao === MODO.COPIAR) {
            this.service = new SesmtQuestionarioCopiarService();
        } else {
            this.service = new SesmtService('/questionario-sesmt');
        }
        this.$serviceCliente = new SesmtService('/customers');
        this.$servicePergunta = new SesmtService('/pergunta-sesmt/findall/ativo');
    },
    async mounted() {
        await this.loadCustomers();
        await this.loadPerguntas();
    },
    watch: {
        'form.inserirAnexo'(newValue) {
            if (!newValue) {
                this.form.anexoObrigatorio = false;
            }
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;

            if (!this.form.inserirAnexo) {
                this.form.anexoObrigatorio = false;
            }

            const formularioFormatado = new FormularioFormatado({
                formQuestionario: this.form,
                perguntasForm: this.perguntasForm,
                clientesSelecionados: this.clientesSelecionados,
                perguntasFormClone: this.perguntasFormClone,
                perguntasRemovidasIds: this.perguntasRemovidasIds
            });

            this.form = { ...formularioFormatado };
        },

        async onAfterSave() {
            this.submitted = false;
        },

        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },

        async onLoadDataEdit(data) {
            this.clientesSelecionados = [
                ...(data.questionario.questionarioSESMTCliente?.map((qc) => {
                    return qc.cliente;
                }) || [])
            ];

            this.clientes = this.clientesSelecionados;
            this.form = data.questionario;

            this.perguntasForm = FormularioEdicaoFormatado.formatar(data.perguntasVinculadas);
            if (this.modoInsercao !== MODO.COPIAR) {
                this.perguntasFormClone = clone(this.perguntasForm);
            }
        },

        hasError() {
            if (!this.form.nome) return true;
            if (!this.form.tipo) return true;
            if (!this.perguntasForm.length) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'O questionário deve conter pelo menos uma pergunta',
                    life: 3000
                });
                return true;
            }
        },

        onError(error) {
            if (error.response?.data?.statusCode === 422) {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response?.data?.message || error.message,
                    life: 3000
                });
            }
        },

        async loadCustomers(event) {
            const companyId = this.form.companies?.map((c) => c.id);
            const filter = event?.target?.value ?? event?.originalEvent?.target?.value;
            const { data } = await this.$serviceCliente.findAll({ filter, companyId });
            const novosClientes = data.items.filter((cliente) => !this.clientesSelecionados.some((selecionado) => selecionado.id === cliente.id));
            this.clientes = this.clientesSelecionados ? [...this.clientesSelecionados, ...novosClientes] : data.items;
        },

        async loadPerguntas(event) {
            const companyId = this.form.companies?.map((c) => c.id);
            const { data } = await this.$servicePergunta.findAll({ filter: event?.value, companyId });
            this.perguntas = data
                .sort((a, b) => {
                    return a.descricao - b.descricao;
                })
                .map((item) => {
                    const tipo = this.tiposPergunta.find((tipoPergunta) => tipoPergunta.value === item.tipo)?.label;

                    return {
                        ...item,
                        pergunta: `${item.descricao} - ${tipo}`
                    };
                });
        }
    },
    provide() {
        return {
            loadPerguntas: this.loadPerguntas,
            perguntasRemovidasIds: this.perguntasRemovidasIds
        };
    }
};
</script>

<style scoped lang="scss">
.multiSelect {
    padding: 0.25rem 0.5rem;
    border-radius: 6px;
}

.selects-cliente {
    border-radius: 6px;
    padding: 0.25rem 0.5rem;
}

.selects {
    border-radius: 6px;
}

.chips {
    height: 37px;
}

.todo-item {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 14px 8px;
    margin-bottom: 3px;
    background-color: #fff;
    box-shadow: 1px 2px 2px #ccc;
    font-size: 22px;
}

.p-dropdown-item {
    color: red;
}
</style>
